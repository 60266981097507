import packageJson from '../../package.json';

export const environment = {
  version: packageJson.version,
  production: true,
  isProduction: false,
  isniPath: 'api/isni',
  isrcPath: 'api/isrc',
  bowiPath: 'api/bowi',
  stripePath: 'api/stripe',
  clientPath: 'api/auth',
  emailPath: 'api/email',
  stripe: {
    products : {
      registrationIsni: {
        product_id: "prod_ROH6L92Ry1PAr3",
        price_id: "price_1QVUYADOtH8OnA5kv5VgVxrE",
        price: 1000
      },
      registrationIsrc: {
        product_id: "prod_ROHCToPgquBdnQ",
        price_id: "price_1QVUe6DOtH8OnA5ke3tilHeh",
        price: 0
      },
      registrationBowi: {
        product_id: "prod_ROHEKSnQKa7nNc",
        price_id: "price_1QVUfvDOtH8OnA5kM0vxnnno",
        price: 0
      }
    }
  }
};
